<template>
  <div class="vps-change">
    <transition name="fade" mode="out-in">
      <!--      <base-loader v-if="!configurator.length" />-->
      <main-card>
        <!--        <template v-if="status === 4 || status === 5 || tariff.in_pay" #header>-->
        <!--          <base-alert :title="$t('disabled.title')">-->
        <!--            {{ $t('disabled.text') }}-->
        <!--          </base-alert>-->
        <!--        </template>-->
        <div class="vps-change-config">
          <component
            :is="componentsByType[item.type]"
            v-for="item in configurator"
            :key="item.intname"
            v-bind="item"
            :shown-period="tariff.period_id"
            class="vps-change-config__item"
            @change="onChange(item, $event)"
          />
        </div>
        <template #footerEnd>
          <div class="vps-change__footer">
            <div v-if="status !== 4 && status !== 5" class="vps-change__summary medium-title">
              <span class="vps-change__summary-text"> {{ $t(`res.${summaryKey}`) }}: </span>
              <span class="vps-change__summary-sum">
                {{ $n(Math.abs(sum), 'currency') }}
              </span>
              <v-popover
                v-if="!!sum"
                placement="top"
                :auto-hide="true"
                popover-class="vps-change__popover"
                class="vps-change__more"
              >
                <plain-button icon="help" class="vps-change__more-btn" />
                <template #popover>
                  <config-cost-details
                    v-if="detailsData"
                    v-bind="detailsData"
                    class="vps-change__details"
                  />
                </template>
              </v-popover>
            </div>
            <div v-else-if="status === 5" class="vps-change__text">
              {{ $t('disabled.note') }}
            </div>
            <!--            <div class="vps-change__order-btns">-->
            <!--              <base-button-->
            <!--                :disabled="status === 4 || status === 5 || tariff.in_pay || isSendingToBasket"-->
            <!--                :loading="isSending"-->
            <!--                class="vps-change__order-btn"-->
            <!--                @click="sum > 0 ? sendToPay() : save()"-->
            <!--              >-->
            <!--                {{ status === 1 || 0 >= sum ? $t('save') : $t('pay') }}-->
            <!--              </base-button>-->
            <!--              &lt;!&ndash;              <base-button&ndash;&gt;-->
            <!--              &lt;!&ndash;                v-if="status !== 1 && sum > 0"&ndash;&gt;-->
            <!--              &lt;!&ndash;                :disabled="&ndash;&gt;-->
            <!--              &lt;!&ndash;                  !hasChanges || status === 4 || status === 5 || tariff.in_pay || isSending&ndash;&gt;-->
            <!--              &lt;!&ndash;                "&ndash;&gt;-->
            <!--              &lt;!&ndash;                :loading="isSendingToBasket"&ndash;&gt;-->
            <!--              &lt;!&ndash;                theme="outlined"&ndash;&gt;-->
            <!--              &lt;!&ndash;                class="vps-change__order-btn"&ndash;&gt;-->
            <!--              &lt;!&ndash;                @click="sendToBasket()"&ndash;&gt;-->
            <!--              &lt;!&ndash;              >&ndash;&gt;-->
            <!--              &lt;!&ndash;                {{ $t('basket') }}&ndash;&gt;-->
            <!--              &lt;!&ndash;              </base-button>&ndash;&gt;-->
            <!--            </div>-->
          </div>
        </template>
      </main-card>
    </transition>
  </div>
</template>

<script>
import { VpsTariff } from '@/models/BillMgr/VpsTariff';
import MainCard from '@/components/MainCard/MainCard.vue';
import SliderBlock from '@/components/Configurator/components/SliderBlock.vue';
// import CheckboxBlock from '@/components/Configurator/components/CheckboxBlock.vue';
// import SelectBlock from '@/components/Configurator/components/SelectBlock.vue';
import TextBlock from '@/components/Configurator/components/TextBlock';
import ConfigCostDetails from '../pages/Information/components/ConfigCostDetails.vue';
import BaseAlert from '@/components/BaseAlert/BaseAlert';
import { debounce } from 'lodash';
import showErrorModal from '@/mixins/showErrorModal';
import wizardConf from '@/mixins/billmgr/wizardConf';
import handleRedirect from '@/mixins/billing/handleRedirect';
import qs from 'qs';
import PaymentMethodConfigurator from '@/models/BillMgr/PaymentMethodConfigurator';
export default {
  name: 'IpOrder',
  // components: { MainCard, SliderBlock, CheckboxBlock, SelectBlock, ConfigCostDetails, BaseAlert },
  components: { MainCard, SliderBlock, TextBlock, ConfigCostDetails, BaseAlert },
  mixins: [showErrorModal, wizardConf, handleRedirect],
  props: {
    tariff: {
      type: Object,
      required: true,
      validator: obj => obj instanceof VpsTariff,
    },
  },
  data() {
    return {
      sum: 0,
      loading: false,
      isSending: false,
      isSendingToBasket: false,
      startFunc: 'payment.add',
      componentsByType: {
        slider: SliderBlock,
        // checkbox: CheckboxBlock,
        // select: SelectBlock,
        text: TextBlock,
      },
      detailsData: null,
      configuratorClass: PaymentMethodConfigurator,
    };
  },
  computed: {
    configurator() {
      return this.tariff.configurator; //.slice(3, 4);
    },
    pricelistId() {
      return this.tariff.pricelistId;
    },
    ext() {
      return this.tariff.addonsExt;
    },
    profile() {
      return this.$store.state.moduleProfile.profile;
    },
    summaryKey() {
      return this.sum > 0 ? 'pay' : this.sum < 0 ? 'refund' : 'def';
    },
    hasChanges() {
      return !!this.sum;
    },
    tools() {
      return this.$store.state.moduleVPS.tools;
    },
    status() {
      return this.tariff.status.code;
    },
  },
  watch: {
    configurator() {
      if (this.configurator.length) this.calcSum();
    },
    pricelistId(val) {
      if (val) this.fetchExt(val);
    },
  },
  mounted() {
    if (this.pricelistId && !this.ext && this.tariff._serverInfo) this.fetchExt(this.pricelistId);
    if (this.configurator.length) this.calcSum();
  },
  methods: {
    onChange(item, payload) {
      const { value, sum } = payload;
      item.currentValue = value;
      if (item.sum !== sum) {
        item.sum = sum;
        this.calcSum();
      }
    },
    calcSum: debounce(function () {
      const data = this.getConfData();
      if (!this.loading) {
        this.$store
          .dispatch('moduleVPS/fetchTariffCalc', {
            ...data,
            elid: this.tariff.id,
            account: this.profile.account,
          })
          .then(data => {
            if (data && data.model && data.model.cost) {
              this.sum = parseFloat(data.model.cost);
              this.detailsData = {
                details: data.model.cost_details,
                total: this.sum,
                expireDate: new Date(data.model.expiredate),
                period: this.tariff.period_id,
              };
            }
          })
          .catch(e => console.error(e))
          .finally(() => (this.loading = false));
      }
    }, 500),
    fetchExt(id) {
      return this.$store.dispatch('moduleVPS/fetchAddons', id);
    },
    getConfData() {
      return this.configurator.reduce((acc, addon) => {
        acc[addon.name] = addon.currentValue;
        return acc;
      }, {});
    },
    getFormData() {
      return {
        ...this.getConfData(),
        elid: this.tariff.id,
        account: this.profile.account,
        func: this.tools.edit.func,
      };
    },
    sendToBasket() {
      this.isSendingToBasket = true;
      const params = {
        clicked_button: 'basket',
        newface: 'on',
      };
      this.save(params, 'basket', 'isSendingToBasket');
    },
    sendToPay() {
      this.isSending = true;
      const params = {
        ...this.getFormData(),
        clicked_button: 'basket',
        newbasket: 'on',
      };
      this.showResFunc.success = false;
      this.$store
        .dispatch('moduleVPS/updateDetail', params)
        .then(data => {
          this.$store.dispatch('moduleBasket/fetchBasket');
          if (data && data.ok && data.ok.type && data.ok.type === 'form' && data.ok.v) {
            const { billorder } = qs.parse(data.ok.v);
            this.startParams = { billorder };
            this.runWizardPay()
              .then(async data => {
                const res = await this.handleRedirect(data.ok);
                if (res.ok) {
                  const text =
                    res.func === 'redirect'
                      ? this.$t('success.redirect')
                      : this.$t('success.pay', { num: this.$n(this.sum, 'currency') });
                  this.showSuccessModal(text);
                }
                //this.updateBalance();
                this.updatePageData();
              })
              .catch(() => {
                const basketItem = this.$store.state.moduleBasket.shadow[billorder][0];
                if (basketItem) {
                  this.$store.dispatch('moduleBasket/removeFromBasket', basketItem).finally(() => {
                    this.$store.dispatch('moduleBasket/fetchBasket');
                  });
                }
              });
          }
        })
        .catch(e => this.showError(e))
        .finally(() => {
          this.isSending = false;
          this.$emit('close');
        });
    },
    save(payload = {}, action = 'update', loader = 'isSending') {
      this[loader] = true;
      const params = {
        ...this.getFormData(),
        ...payload,
      };
      this.$store
        .dispatch('moduleVPS/updateDetail', params)
        .then(data => {
          if (data.ok) {
            this.showSuccessModal(
              this.$t(`success.${action}`, { num: this.$n(this.sum, 'currency') })
            );
            this.updatePageData();
          }
        })
        .catch(e => this.showError(e))
        .finally(() => {
          this[loader] = false;
          this.$emit('close');
        });
    },
    updatePageData() {
      return Promise.all([
        this.$store.dispatch('moduleVPS/fetchDetail', { id: this.tariff.id }),
        this.$store.dispatch('moduleProfile/setProfileInfo'),
        this.$store.dispatch('moduleBasket/fetchBasket'),
      ]).then(() => this.fetchExt(this.pricelistId));
    },
    showSuccessModal(text) {
      this.$modals.open({
        name: 'SuccessOrder',
        size: 'small',
        text,
      });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "res": {
      "def": "Итого",
      "pay": "К оплате",
      "refund": "Вернём"
    },
    "order": "Заказать",
    "pay": "Оплатить",
    "basket": "В корзину",
    "save": "Сохранить",
    "disabled": {
      "title": "Изменение параметров недоступно",
      "text": "Виртуальный сервер находится в процессе обработки.",
      "note": "Дождитесь завершения обработки"
    },
    "success": {
      "basket": "Заказ на {num} успешно добавлен в корзину",
      "pay": "Конфигурация успешно обновлена, {num} списано с лицевого счета",
      "redirect": "Конфигурация успешно обновлена, продолжите оплату и дождитесь обновления данных",
      "update": "Изменения успешно сохранены."
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.vps-change {
  margin-top: 1.5rem;

  +breakpoint(sm-and-up) {
    margin-top: 2.5rem;
  }

  &__footer {
    flexy(flex-end, flex-end);
    flex-direction: column;

    +breakpoint(sm-and-up) {
      flex-direction: row;
      align-items: center;
      padding-bottom: 1rem;
    }
  }

  &__summary {
    flexy(flex-end, center);
    margin-bottom: 1rem;

    +breakpoint(sm-and-up) {
      margin-bottom: 0;
      margin-right: 2.5rem;
    }

    &-text {
      margin-right: 1.5rem;
    }
    &-sum {
      text-transform: lowercase;
    }
  }

  &__text {
    margin-bottom: 1rem;
    +breakpoint(sm-and-up) {
      margin-bottom: 0;
      margin-right: 2.5rem;
    }
  }

  &__order-btns {
    margin: -0.5rem;
  }

  &__order-btn {
    min-width: 160px;
    margin: 0.5rem;
    width: calc(100% - 1rem);

    +breakpoint(ms-and-up) {
      width: auto;
    }
  }

  &-config {
    +breakpoint(sm-and-up) {
      margin-top: 1rem;
    }

    &__item {
      & + & {
        margin-top: 1.5rem;

        +breakpoint(sm-and-up) {
          margin-top: 2.5rem;
        }
      }
    }
  }

  &__more {
    margin-left: 1rem;

    &-btn {
      color: $color-light.medium;
      opacity: 0.5;
      transition: opacity 0.3s;

      &:hover {
        opacity: 1;
      }
    }
  }
  &__details {
    width: calc(100vw - 3.25rem);

    +breakpoint(sm-and-up) {
      width: auto;
      max-width: calc(100vw - 3.25rem);
    }
  }
}
</style>
